body.template-press-info {

	#ctas {
		margin: auto;

		@include tablet-landscape {
			margin: 0 0 0 auto;
		}


		.cta {
			margin: 0 0 20px 0;

			.btn {
				max-width: 200px;
				font-size: 1.125em;
				padding: 16px 24px 14px;
			}
		}

	}


}