/*

    Theme Name: Callahan Award
    Theme URI: https://callahanaward.com/
    Author: Andrew Lovseth
    Author URI: http://www.andrewlovseth.com/
    Description: Theme for the Callahan Award website
    Version: 1.0

*/






/*

    ----------------------------------------------------------------------
                                01 Global
    ----------------------------------------------------------------------

*/

@import "global/variables";
@import "global/mixins";
@import "global/reset";
@import "global/typography";
@import "global/layout";

@import "plugins/slick";
@import "plugins/slick-theme";




/*

    ----------------------------------------------------------------------
                                02 Sections
    ----------------------------------------------------------------------

*/

@import "header";
@import "footer";
@import "sponsors";




/*

    ----------------------------------------------------------------------
                                03 Templates
    ----------------------------------------------------------------------

*/

@import "home";
@import "news";
@import "single-post";
@import "press-info";
@import "page";
@import "history";

@import "nominees-grid";
@import "nominee-single";