body {
	background: $white;
}

.wrapper {
	margin: 0 auto;
	max-width: 1200px; 
	padding: 0 25px;
    transition: padding 0.2s ease-in;

    @include tablet-portrait {
		padding: 0 50px;
    }

	@media screen and (min-width: 1260px) {
		padding: 0;
	}
}

body.error404 {
	#main {
		article {
			min-height: 600px;
		}
	}
}

.cover {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

.hero-link {
	display: block;
}

.hero-image {
	height: 240px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;


    @include mobile-landscape {
		height: 360px;
    }

	@include tablet-landscape {
		height: 480px;
	}

	@include desktop {
		height: 600px;
	}

	.wrapper {
		display: flex;
		align-items: flex-end;
		height: 100%;
	}

	.info {
		padding: 0 0 25px 0;

	    @include mobile-landscape {
			padding: 0 0 50px 0;
	    }
	}

	h1 {
		color: $white;
		text-transform: uppercase;
		letter-spacing: 0.075em;
		font-size: 1.25em;
		max-width: 900px;

	    @include mobile-landscape {
			font-size: 2.25em;
	    }

		@include tablet-landscape {
			font-size: 3em;
		}

		@include desktop {
			font-size: 3.5em;
		}

		span {
			background: rgba(11, 35, 63, 0.8);
			padding: 10px;
			display: inline-block;

			@include mobile-landscape {
				padding: 15px 20px;
			}
		}
	}

	h2 {
		color: $white;
		text-transform: uppercase;
		font-size: 0.875em;
		letter-spacing: 0.075em;
		font-weight: 400;

	    @include tablet-portrait {
			font-size: 1.25em;
	    }

		span {
			background: rgba(163, 32, 55, 0.8);
			padding: 10px;
			display: inline-block;

			@include mobile-landscape {
				padding: 15px 20px;
			}
		}
	}




}


section#main  {
	padding: 30px 0;

	@include tablet-portrait {
		padding: 50px 0;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	article {
		width: 100%;
		padding: 0 0 50px 0;

		@include tablet-landscape {
			width: 55%;
			padding: 0;
		}
	}

	.aside-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include tablet-landscape {
			width: 42.5%;
		}
	}

}

aside#latest-news {

	article {
		border: 1px solid rgba(0, 42, 92, 0.2);
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
		padding: 20px !important;
		width: 100% !important;
		margin: 0 0 20px 0;


		h5 {
			color: $red;
			font-size: 0.875em;
			padding: 0 0 5px 0;
		}

		h4 {
			a {
				color: $blue;
				font-size: 1.125em;

				&:hover {
					text-decoration: underline;
				}
			}
		}

	}

	.btn {
		margin: 10px auto 0 auto;
		max-width: 200px;
	}


}







