/* Colors
----------------------------------*/
$white: #fff;
$black: #000;
$slate: #1a1a1a;
$red: #A32037;
$blue: #0B233F;
$light-gray: #eef0f2;
$deep-purple: #1a1423;



/* Typography
----------------------------------*/
$merriweather: 'Merriweather', serif;
$montserrat: 'Montserrat', sans-serif;
