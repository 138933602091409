#sponsors {
	background: $light-gray;
	padding: 50px 0 0 0;

	h2 {
		color: $red;
		text-transform: uppercase;
		text-align: center;
		padding: 0 0 50px 0;
	}

	#sponsors-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;

		a {
			width: 100%;
			display: block;
			margin: 0 auto 50px auto;

			@include tablet-landscape {
				width: 33.3333%;

				&.five-ultimate {
					img {
						max-width: 400px;
					}
				}
			}

			img {
				margin: 0 auto;
				max-width: 200px;
			}

		}

	}

}

