@charset "UTF-8";
/*

    Theme Name: Callahan Award
    Theme URI: https://callahanaward.com/
    Author: Andrew Lovseth
    Author URI: http://www.andrewlovseth.com/
    Description: Theme for the Callahan Award website
    Version: 1.0

*/
/*

    ----------------------------------------------------------------------
                                01 Global
    ----------------------------------------------------------------------

*/
/* Colors
----------------------------------*/
/* Typography
----------------------------------*/
/* Media Queries
----------------------------------*/
/* HTML5 */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

/* HTML & BODY */
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif; }

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

/* Links */
a {
  text-decoration: none; }

a:focus {
  outline: none; }

a:hover, a:active {
  outline: none; }

/* Typography */
abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 600; }

i, em {
  font-style: italic; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000; }

p, pre {
  margin: 1em 0; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: normal;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

/* Lists */
dd {
  margin: 0 0 0 40px; }

ul, ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

/* Embedded Content */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

/* Figure */
figure {
  margin: 0; }

/* Forms */
form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/* Tables */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

/* Absolute Positioning */
div, article, section, header, footer, nav, figure, li {
  position: relative; }

/* Selection  */
::-moz-selection {
  background: #A32037;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #A32037;
  color: #fff;
  text-shadow: none; }

/* Clearfix */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

/* Border-Box */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* Image replacement */
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px; }

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 100%; }

/* Responsive Image */
img {
  display: block;
  max-width: 100%; }

html, input, textarea {
  -webkit-font-smoothing: antialiased; }

body {
  text-rendering: optimizeLegibility; }

body, input, textarea {
  color: #1a1a1a;
  font: 400 100%/1 "Montserrat", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold; }

a {
  color: #A32037;
  transition: color 0.2s ease-in-out; }

.btn {
  background: #0B233F;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  font-size: 1.25em;
  line-height: 1em;
  display: block;
  padding: 15px 24px 13px;
  text-align: center; }
  .btn.red {
    background: #A32037; }

#main article h2 {
  color: #0B233F;
  text-transform: uppercase;
  font-size: 2.125em;
  padding: 0 0 15px 0; }

#main article p {
  font-size: 1em;
  line-height: 1.5555em;
  margin: 0 0 30px 0; }

#main aside h3 {
  color: #A32037;
  text-transform: uppercase;
  font-size: 1.375em;
  letter-spacing: 0.075em;
  padding: 0 0 20px 0; }

article.default {
  font-family: "Merriweather", serif; }
  article.default p, article.default ul, article.default ol {
    font-size: 1em;
    line-height: 1.5em;
    margin: 0 0 30px 0; }
    @media screen and (min-width: 768px) {
      article.default p, article.default ul, article.default ol {
        font-size: 1.25em;
        margin: 0 0 40px 0; } }
  article.default ul, article.default ol {
    max-width: 560px;
    padding: 0 0 0 30px; }
    @media screen and (min-width: 768px) {
      article.default ul, article.default ol {
        padding: 0 0 0 60px; } }
    article.default ul li, article.default ol li {
      margin: 0 0 10px 0; }
  article.default ul li {
    list-style-type: disc; }
  article.default ol li {
    list-style-type: decimal; }
  article.default h2 {
    font-size: 1.75em;
    color: #0B233F;
    padding: 10px 0 10px 0; }
    @media screen and (min-width: 768px) {
      article.default h2 {
        font-size: 2em; } }
  article.default * + h2 {
    padding-top: 50px; }
  article.default h3 {
    font-size: 1.25em;
    color: #0B233F;
    padding: 10px 0 10px 0; }
    @media screen and (min-width: 768px) {
      article.default h3 {
        font-size: 1.5em; } }
  article.default h4 {
    font-size: 1em;
    color: #0B233F;
    text-transform: uppercase;
    padding: 10px 0 10px 0; }
    @media screen and (min-width: 768px) {
      article.default h4 {
        font-size: 1.25em; } }
  article.default a {
    text-decoration: underline; }
  article.default blockquote {
    padding: 5px 0 5px 30px;
    border-left: 5px solid #eef0f2;
    max-width: 540px;
    margin: 0 0 30px 0; }
    @media screen and (min-width: 768px) {
      article.default blockquote {
        font-size: 1.25em;
        margin: 0 0 40px 0; } }
    @media screen and (min-width: 768px) {
      article.default blockquote {
        margin: 0 0 40px 60px; } }
    article.default blockquote p {
      font-size: 0.8125em;
      line-height: 2em; }
      article.default blockquote p:last-of-type {
        margin: 0; }

body {
  background: #fff; }

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 25px;
  transition: padding 0.2s ease-in; }
  @media screen and (min-width: 768px) {
    .wrapper {
      padding: 0 50px; } }
  @media screen and (min-width: 1260px) {
    .wrapper {
      padding: 0; } }

body.error404 #main article {
  min-height: 600px; }

.cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.hero-link {
  display: block; }

.hero-image {
  height: 240px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media screen and (min-width: 640px) {
    .hero-image {
      height: 360px; } }
  @media screen and (min-width: 1024px) {
    .hero-image {
      height: 480px; } }
  @media screen and (min-width: 1280px) {
    .hero-image {
      height: 600px; } }
  .hero-image .wrapper {
    display: flex;
    align-items: flex-end;
    height: 100%; }
  .hero-image .info {
    padding: 0 0 25px 0; }
    @media screen and (min-width: 640px) {
      .hero-image .info {
        padding: 0 0 50px 0; } }
  .hero-image h1 {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    font-size: 1.25em;
    max-width: 900px; }
    @media screen and (min-width: 640px) {
      .hero-image h1 {
        font-size: 2.25em; } }
    @media screen and (min-width: 1024px) {
      .hero-image h1 {
        font-size: 3em; } }
    @media screen and (min-width: 1280px) {
      .hero-image h1 {
        font-size: 3.5em; } }
    .hero-image h1 span {
      background: rgba(11, 35, 63, 0.8);
      padding: 10px;
      display: inline-block; }
      @media screen and (min-width: 640px) {
        .hero-image h1 span {
          padding: 15px 20px; } }
  .hero-image h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.875em;
    letter-spacing: 0.075em;
    font-weight: 400; }
    @media screen and (min-width: 768px) {
      .hero-image h2 {
        font-size: 1.25em; } }
    .hero-image h2 span {
      background: rgba(163, 32, 55, 0.8);
      padding: 10px;
      display: inline-block; }
      @media screen and (min-width: 640px) {
        .hero-image h2 span {
          padding: 15px 20px; } }

section#main {
  padding: 30px 0; }
  @media screen and (min-width: 768px) {
    section#main {
      padding: 50px 0; } }
  section#main .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
  section#main article {
    width: 100%;
    padding: 0 0 50px 0; }
    @media screen and (min-width: 1024px) {
      section#main article {
        width: 55%;
        padding: 0; } }
  section#main .aside-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media screen and (min-width: 1024px) {
      section#main .aside-wrapper {
        width: 42.5%; } }

aside#latest-news article {
  border: 1px solid rgba(0, 42, 92, 0.2);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px !important;
  width: 100% !important;
  margin: 0 0 20px 0; }
  aside#latest-news article h5 {
    color: #A32037;
    font-size: 0.875em;
    padding: 0 0 5px 0; }
  aside#latest-news article h4 a {
    color: #0B233F;
    font-size: 1.125em; }
    aside#latest-news article h4 a:hover {
      text-decoration: underline; }

aside#latest-news .btn {
  margin: 10px auto 0 auto;
  max-width: 200px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 60px;
  width: 60px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  z-index: 100;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.4; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 20px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 20px; }
  @media screen and (min-width: 1280px) {
    .slick-prev {
      left: 40px; }
      [dir="rtl"] .slick-prev {
        left: auto;
        right: 40px; } }
  .slick-prev:before {
    background: url(images/left-arrow.png) no-repeat 0 0;
    width: 60px;
    height: 60px;
    content: '';
    display: block; }

.slick-next {
  right: 20px; }
  [dir="rtl"] .slick-next {
    left: 20px;
    right: auto; }
  @media screen and (min-width: 1280px) {
    .slick-next {
      right: 40px; }
      [dir="rtl"] .slick-next {
        left: 40px;
        right: auto; } }
  .slick-next:before {
    background: url(images/right-arrow.png) no-repeat 0 0;
    width: 60px;
    height: 60px;
    content: '';
    display: block; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 5px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  @media screen and (min-width: 640px) {
    .slick-dots {
      bottom: 15px; } }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      width: 12px;
      height: 12px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        background-color: #fff;
        opacity: 0.4;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #fff;
      opacity: 0.85; }

/*

    ----------------------------------------------------------------------
                                02 Sections
    ----------------------------------------------------------------------

*/
header {
  padding: 20px 0;
  border-top: 5px solid #A32037; }
  @media screen and (min-width: 768px) {
    header {
      border-top: none;
      padding: 0;
      margin-bottom: -110px; } }
  @media screen and (min-width: 1280px) {
    header {
      margin-bottom: -150px; } }
  header .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  header .logo {
    z-index: 100; }
    header .logo img {
      max-width: 140px; }
      @media screen and (min-width: 768px) {
        header .logo img {
          max-width: 160px;
          transform: translateY(-50px);
          margin-bottom: -50px; } }
      @media screen and (min-width: 1280px) {
        header .logo img {
          max-width: 200px;
          transform: translateY(-50px);
          margin-bottom: -50px; } }
  header #toggle {
    width: 34px;
    cursor: pointer;
    padding: 8px 5px 8px 5px;
    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
    margin-right: 10px; }
    @media screen and (min-width: 768px) {
      header #toggle {
        display: none; } }
    header #toggle .patty {
      width: 40px;
      height: 5px;
      background: #0B233F;
      transition: all 0.3s; }
      header #toggle .patty:after, header #toggle .patty:before {
        content: '';
        display: block;
        width: 40px;
        height: 5px;
        background: #0B233F;
        position: absolute;
        z-index: -1;
        transition: all 0.3s; }
      header #toggle .patty:before {
        top: -10px; }
      header #toggle .patty:after {
        top: 10px; }
  header.open #toggle .patty {
    background: none; }
    header.open #toggle .patty:after {
      top: 0;
      transform: rotate(45deg); }
    header.open #toggle .patty:before {
      top: 0;
      transform: rotate(-45deg); }

nav#bar {
  background: #A32037;
  color: #fff;
  padding: 20px 0;
  display: none; }
  @media screen and (min-width: 768px) {
    nav#bar {
      display: block !important; } }
  nav#bar .menu {
    width: 100%;
    padding: 10px 0 0 0; }
    @media screen and (min-width: 768px) {
      nav#bar .menu {
        display: flex !important;
        width: auto;
        padding: 0 0 0 180px; } }
    @media screen and (min-width: 1280px) {
      nav#bar .menu {
        padding-left: 240px; } }
    nav#bar .menu .link {
      display: flex; }
      @media screen and (min-width: 768px) {
        nav#bar .menu .link + .link {
          margin: 0 0 0 25px; }
          nav#bar .menu .link + .link:before {
            content: '·';
            padding-right: 25px; } }
    nav#bar .menu a {
      color: #fff;
      display: block;
      font-size: 0.875em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 500;
      padding: 15px 0; }
      @media screen and (min-width: 768px) {
        nav#bar .menu a {
          padding: 0; } }
      nav#bar .menu a:hover {
        text-decoration: underline; }
  nav#bar .mobile-links {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 10px;
    margin-top: 20px; }
    nav#bar .mobile-links a {
      text-transform: none;
      font-weight: 400;
      letter-spacing: 0.05em; }
    @media screen and (min-width: 768px) {
      nav#bar .mobile-links {
        display: none; } }

#utility-nav {
  background: #0B233F;
  padding: 20px 0 18px 0;
  display: none; }
  @media screen and (min-width: 768px) {
    #utility-nav {
      display: block; } }
  #utility-nav .wrapper {
    display: flex;
    justify-content: flex-end; }
  #utility-nav a {
    font-size: 0.8125em;
    letter-spacing: 0.02em;
    font-weight: 500;
    display: block;
    color: #fff; }
    #utility-nav a + a {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid rgba(255, 255, 255, 0.25); }
    #utility-nav a:hover {
      text-decoration: underline; }

footer {
  background: #A32037;
  color: #fff;
  padding: 100px 0 50px 0; }
  footer .logo a {
    display: block;
    max-width: 100px; }
  footer .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    footer .wrapper .col {
      width: 100%;
      padding: 0 0 25px 0; }
      @media screen and (min-width: 1024px) {
        footer .wrapper .col {
          width: 25%;
          text-align: left;
          padding: 0; }
          footer .wrapper .col.logo {
            width: 12.5%; } }
  footer .link + .link {
    padding-top: 15px; }
  footer .link a {
    color: #fff;
    font-size: 0.875em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500; }
    footer .link a:hover {
      text-decoration: underline; }
  footer .copyright {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 100px 0 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding: 20px 0 0 0; }
    footer .copyright p {
      font-weight: normal;
      font-size: 0.75em;
      color: rgba(255, 255, 255, 0.7);
      padding: 0; }
      footer .copyright p.credits {
        text-align: right; }
        footer .copyright p.credits a {
          color: rgba(255, 255, 255, 0.7);
          border-bottom: 1px dotted; }

#sponsors {
  background: #eef0f2;
  padding: 50px 0 0 0; }
  #sponsors h2 {
    color: #A32037;
    text-transform: uppercase;
    text-align: center;
    padding: 0 0 50px 0; }
  #sponsors #sponsors-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto; }
    #sponsors #sponsors-wrapper a {
      width: 100%;
      display: block;
      margin: 0 auto 50px auto; }
      @media screen and (min-width: 1024px) {
        #sponsors #sponsors-wrapper a {
          width: 33.3333%; }
          #sponsors #sponsors-wrapper a.five-ultimate img {
            max-width: 400px; } }
      #sponsors #sponsors-wrapper a img {
        margin: 0 auto;
        max-width: 200px; }

/*

    ----------------------------------------------------------------------
                                03 Templates
    ----------------------------------------------------------------------

*/
body.home .hero-image h1 span {
  display: block; }

body.home section#main .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }

body.home section#main section#about {
  width: 100%; }
  @media screen and (min-width: 768px) {
    body.home section#main section#about {
      width: 65%; } }
  @media screen and (min-width: 1280px) {
    body.home section#main section#about {
      width: 78.5%; } }
  body.home section#main section#about .headline {
    padding-bottom: 25px; }
    body.home section#main section#about .headline h3 {
      color: #0B233F;
      text-transform: uppercase;
      letter-spacing: 0.075em;
      font-size: 1.375em; }
  body.home section#main section#about .copy {
    font-family: "Merriweather", serif;
    max-width: 640px; }
    body.home section#main section#about .copy.p2 p {
      font-size: 1.125em;
      line-height: 1.5em; }
      body.home section#main section#about .copy.p2 p + p {
        padding-top: 40px; }

body.home section#main aside#latest-news {
  width: 100%;
  padding: 50px 0; }
  @media screen and (min-width: 768px) {
    body.home section#main aside#latest-news {
      width: 30%;
      padding: 0; } }
  @media screen and (min-width: 1280px) {
    body.home section#main aside#latest-news {
      width: 18.5%; } }

section#news {
  padding: 30px 0; }
  @media screen and (min-width: 768px) {
    section#news {
      padding: 50px 0; } }
  section#news .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
  section#news article {
    width: 100%;
    padding: 0 0 75px 0; }
    @media screen and (min-width: 640px) {
      section#news article {
        width: 47.5%; } }
    section#news article .info {
      background: #fff;
      border: 1px solid rgba(0, 42, 92, 0.2);
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      width: 95%;
      margin: -20px auto 0 auto; }
      @media screen and (min-width: 640px) {
        section#news article .info {
          margin: -20px auto 0 auto;
          padding: 40px;
          width: 90%; } }
      section#news article .info h4 {
        color: #A32037;
        font-size: 0.875em;
        padding: 0 0 5px 0; }
        @media screen and (min-width: 640px) {
          section#news article .info h4 {
            font-size: 1em; } }
      section#news article .info h3 {
        font-size: 1.5em;
        padding: 0 0 20px 0; }
        @media screen and (min-width: 640px) {
          section#news article .info h3 {
            font-size: 2em; } }
        section#news article .info h3 a {
          color: #0B233F; }
      section#news article .info p {
        font-family: "Merriweather", serif;
        font-size: 0.8125em;
        line-height: 1.75em; }
  section#news #ajax-load-more {
    width: 100%; }
  section#news .alm-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
    section#news .alm-listing article {
      width: 100%;
      padding: 0 0 75px 0; }
      @media screen and (min-width: 640px) {
        section#news .alm-listing article {
          width: 47.5%; } }
  section#news .alm-btn-wrap {
    text-align: center;
    width: 100%; }
    section#news .alm-btn-wrap button {
      border: none;
      margin: 0 auto; }
      section#news .alm-btn-wrap button:active, section#news .alm-btn-wrap button:focus {
        outline: none; }
      section#news .alm-btn-wrap button.done {
        display: none; }

@media screen and (min-width: 768px) {
  body.template-single-post #news {
    padding: 100px 0; } }

body.template-single-post #news article {
  font-family: "Merriweather", serif;
  width: 100%;
  max-width: 720px;
  padding: 0; }
  @media screen and (min-width: 1024px) {
    body.template-single-post #news article {
      margin: 0 0 0 10%; } }

body.template-press-info #ctas {
  margin: auto; }
  @media screen and (min-width: 1024px) {
    body.template-press-info #ctas {
      margin: 0 0 0 auto; } }
  body.template-press-info #ctas .cta {
    margin: 0 0 20px 0; }
    body.template-press-info #ctas .cta .btn {
      max-width: 200px;
      font-size: 1.125em;
      padding: 16px 24px 14px; }

body.template-page article {
  padding: 50px 0 50px 0; }
  @media screen and (min-width: 768px) {
    body.template-page article {
      padding: 150px 0 75px 0; } }
  body.template-page article .headline {
    padding: 0 0 50px 0; }
    body.template-page article .headline h1 {
      color: #0B233F;
      font-size: 1.75em;
      text-transform: uppercase;
      letter-spacing: 0.075em;
      font-weight: 600; }
      @media screen and (min-width: 768px) {
        body.template-page article .headline h1 {
          font-size: 2.5em; } }
  body.template-page article .copy {
    font-family: "Merriweather", serif;
    max-width: 800px; }
    body.template-page article .copy h4 {
      font-size: 1.125em;
      font-weight: 600;
      color: #A32037;
      padding-bottom: 10px; }
      @media screen and (min-width: 768px) {
        body.template-page article .copy h4 {
          font-size: 1.25em; } }
    body.template-page article .copy p {
      font-size: 1em;
      line-height: 1.5em; }
      @media screen and (min-width: 768px) {
        body.template-page article .copy p {
          font-size: 1.125em; } }
      body.template-page article .copy p + p {
        padding-top: 40px; }
      body.template-page article .copy p + h4 {
        padding-top: 40px; }

body.page-template-history .winners {
  width: 100%;
  padding-top: 50px; }
  body.page-template-history .winners > h3 {
    padding-bottom: 20px; }
  body.page-template-history .winners .table-header,
  body.page-template-history .winners .table-body .entry {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    body.page-template-history .winners .table-header .year,
    body.page-template-history .winners .table-body .entry .year {
      flex: 1; }
    body.page-template-history .winners .table-header .division,
    body.page-template-history .winners .table-body .entry .division {
      flex: 2;
      padding-right: 10px; }
  body.page-template-history .winners .table-header {
    background: #A32037;
    padding: 10px 20px; }
    body.page-template-history .winners .table-header h5 {
      text-transform: uppercase;
      letter-spacing: 0 0.75em;
      color: #fff; }
  body.page-template-history .winners .table-body .entry {
    padding: 20px; }
    body.page-template-history .winners .table-body .entry:nth-child(even) {
      background: #eef0f2; }
    body.page-template-history .winners .table-body .entry .year h4 {
      font-size: 1em;
      color: #1a1a1a; }
    body.page-template-history .winners .table-body .entry .division h3 {
      font-size: 1em;
      color: #0B233F;
      padding: 0;
      font-weight: 600; }
    body.page-template-history .winners .table-body .entry .division p {
      padding: 5px 0 0 0;
      margin: 0 !important;
      font-size: 0.8125em !important;
      font-style: italic; }

#nominees {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto; }
  #nominees .header {
    width: 100%;
    padding: 25px 0 50px 0;
    text-align: center; }
    #nominees .header h2 {
      color: #A32037;
      text-transform: uppercase;
      letter-spacing: 0.05em; }
  #nominees .nominee {
    width: 48%;
    margin: 0 0 50px 0; }
    @media screen and (min-width: 640px) {
      #nominees .nominee {
        width: 31.3333%; } }
    @media screen and (min-width: 1024px) {
      #nominees .nominee {
        margin: 0 0 100px 0; } }
    #nominees .nominee .headshot img {
      margin: 0 auto; }
    #nominees .nominee .info {
      text-align: center;
      padding: 10px 0 0 0; }
      #nominees .nominee .info h3 {
        font-size: 1.25em;
        width: 100%;
        text-align: center; }
        #nominees .nominee .info h3 em {
          display: block;
          font-family: "Merriweather", serif;
          font-weight: 500;
          font-style: italic;
          font-size: 0.7em;
          padding: 10px 0 0 0; }
        #nominees .nominee .info h3 a {
          color: #A32037; }
          #nominees .nominee .info h3 a em {
            color: #1a1a1a; }

body.template-single-women section#main,
body.template-single-men section#main {
  padding: 75px 0; }
  @media screen and (min-width: 1024px) {
    body.template-single-women section#main article,
    body.template-single-men section#main article {
      width: 66.6666%; } }
  body.template-single-women section#main article #bio img,
  body.template-single-men section#main article #bio img {
    float: left;
    max-width: 150px;
    margin: 10px 20px 20px 0; }
  body.template-single-women section#main article #bio p,
  body.template-single-men section#main article #bio p {
    font-family: "Merriweather", serif;
    font-size: 1em;
    line-height: 1.75em; }
  body.template-single-women section#main article #bio ul, body.template-single-women section#main article #bio ol,
  body.template-single-men section#main article #bio ul,
  body.template-single-men section#main article #bio ol {
    margin: 0 0 30px 30px; }
    body.template-single-women section#main article #bio ul li, body.template-single-women section#main article #bio ol li,
    body.template-single-men section#main article #bio ul li,
    body.template-single-men section#main article #bio ol li {
      margin: 0 0 10px 0;
      font-family: "Merriweather", serif;
      font-size: 0.875em;
      line-height: 1.5em; }
  body.template-single-women section#main article #bio ul,
  body.template-single-men section#main article #bio ul {
    list-style-type: disc; }
  body.template-single-women section#main article #bio ol,
  body.template-single-men section#main article #bio ol {
    list-style-type: decimal; }
  body.template-single-women section#main article #bio h4,
  body.template-single-men section#main article #bio h4 {
    padding: 0 0 10px 0;
    font-size: 1.25em;
    text-transform: uppercase;
    letter-spacing: 0.02em; }
  body.template-single-women section#main article #bio .btn,
  body.template-single-men section#main article #bio .btn {
    display: inline-block;
    font-size: 1em; }
  body.template-single-women section#main .aside-wrapper,
  body.template-single-men section#main .aside-wrapper {
    justify-content: flex-start; }
    @media screen and (min-width: 1024px) {
      body.template-single-women section#main .aside-wrapper,
      body.template-single-men section#main .aside-wrapper {
        padding: 0 0 0 40px;
        width: 33.3333%; } }
  body.template-single-women section#main aside#vitals,
  body.template-single-men section#main aside#vitals {
    width: 100%; }
    body.template-single-women section#main aside#vitals h3,
    body.template-single-men section#main aside#vitals h3 {
      padding: 0 0 10px 0;
      font-size: 1.25em;
      border-bottom: 2px solid;
      letter-spacing: 0.075em; }
      body.template-single-women section#main aside#vitals h3 + h5,
      body.template-single-men section#main aside#vitals h3 + h5 {
        padding-top: 20px; }
    body.template-single-women section#main aside#vitals h5,
    body.template-single-men section#main aside#vitals h5 {
      font-size: 0.875em;
      text-transform: uppercase;
      letter-spacing: 0.075em;
      padding-bottom: 5px;
      color: #0B233F; }
    body.template-single-women section#main aside#vitals p,
    body.template-single-men section#main aside#vitals p {
      padding: 0 0 40px 0;
      font-size: 0.875em;
      font-family: "Merriweather", serif; }
