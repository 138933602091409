html, input, textarea {
	-webkit-font-smoothing: antialiased;
}
body {
	text-rendering: optimizeLegibility;
}

body, input, textarea {
	color: $slate;
	font: 400 100%/1 $montserrat;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $montserrat;
	font-weight: bold;
}

a {
	color: $red;
	transition: color 0.2s ease-in-out;
}

.btn {
	background: $blue;
	color: $white;
	font-family: $montserrat;
	font-weight: 600;
	letter-spacing: 0.075em;
	text-transform: uppercase;
	font-size: 1.25em;
	line-height: 1em;
	display: block;
	padding: 15px 24px 13px;
	text-align: center;

	&.red {
		background: $red;
	}
}


#main {

	article {
		h2 {
			color: $blue;
			text-transform: uppercase;
			font-size: 2.125em;
			padding: 0 0 15px 0;
		}

		p {
			font-size: 1em;
			line-height: 1.5555em;
			margin: 0 0 30px 0;
		}

	}

	aside {
		h3 {
			color: $red;
			text-transform: uppercase;
			font-size: 1.375em;
			letter-spacing: 0.075em;
			padding: 0 0 20px 0;
		}

	}

}


article.default {
	font-family: $merriweather;


	p, ul, ol {
		font-size: 1em;
		line-height: 1.5em;
		margin: 0 0 30px 0;

		@include tablet-portrait {
			font-size: 1.25em;
			margin: 0 0 40px 0;
		}

	}

	ul, ol {
		max-width: 560px;
		padding: 0 0 0 30px;

		@include tablet-portrait {
			padding: 0 0 0 60px;
		}

		li {
			margin: 0 0 10px 0;
		}
	}

	ul {
		li {
			list-style-type: disc;
		}
	}

	ol {
		li {
			list-style-type: decimal;
		}
	}

	h2 {
		font-size: 1.75em;
		color: $blue;
		padding: 10px 0 10px 0;

		@include tablet-portrait {
			font-size: 2em;
		}
	}


	* + h2 {
		padding-top: 50px;
	}

	h3 {
		font-size: 1.25em;
		color: $blue;
		padding: 10px 0 10px 0;

		@include tablet-portrait {
			font-size: 1.5em;
		}
	}

	h4{
		font-size: 1em;
		color: $blue;
		text-transform: uppercase;
		padding: 10px 0 10px 0;
		
		@include tablet-portrait {
			font-size: 1.25em;
		}
	}

	a {
		text-decoration: underline;
	}

	blockquote {
		padding: 5px 0 5px 30px;
		border-left: 5px solid $light-gray;
		max-width: 540px;
		margin: 0 0 30px 0;

		@include tablet-portrait {
			font-size: 1.25em;
			margin: 0 0 40px 0;
		}


		@include tablet-portrait {
			margin: 0 0 40px 60px;
		}

		p {
			font-size: 0.8125em;
			line-height: 2em;

			&:last-of-type {
				margin: 0;
			}


		}
	}

	
}
