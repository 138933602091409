body.template-single-women,
body.template-single-men {

	section#main {
		padding: 75px 0;

		article {

			@include tablet-landscape {
				width: 66.6666%;
			}

			#bio {

				img {
					float: left;
					max-width: 150px;
					margin: 10px 20px 20px 0;
				}
				p {
					font-family: $merriweather;
					font-size: 1em;
					line-height: 1.75em;
				}

				ul, ol {
					margin: 0 0 30px 30px;

					li {
						margin: 0 0 10px 0;
						font-family: $merriweather;
						font-size: 0.875em;
						line-height: 1.5em;
					}
				}

				ul {
					list-style-type: disc;
				}

				ol{
					list-style-type: decimal;
				}

				h4 {
					padding: 0 0 10px 0;
					font-size: 1.25em;
					text-transform: uppercase;
					letter-spacing: 0.02em;
				}

				.btn {
					display: inline-block;
					font-size: 1em;
				}
			}


		}

		.aside-wrapper {
			justify-content: flex-start;

			@include tablet-landscape {
				padding: 0 0 0 40px;
				width: 33.3333%;
			}
		}

		aside#vitals {
			width: 100%;

			h3 {
				padding: 0 0 10px 0;
				font-size: 1.25em;
				border-bottom: 2px solid;
				letter-spacing: 0.075em;

				& + h5 {
					padding-top: 20px;
				}
			}


			h5 {
				font-size: 0.875em;
				text-transform: uppercase;
				letter-spacing: 0.075em;
				padding-bottom: 5px;
				color: $blue;
			}

			p {
				padding: 0 0 40px 0;
				font-size: 0.875em;
				font-family: $merriweather;
			}
		}

	}
}