footer {
	background: $red;
	color: $white;
	padding: 100px 0 50px 0;

	.logo {

		a {
			display: block;
			max-width: 100px;
		}
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.col {
			width: 100%;
			padding: 0 0 25px 0;

			@include tablet-landscape {
				width: 25%;
				text-align: left;
				padding: 0;


				&.logo {
					width: 12.5%;
				}
			}
			
		}

	}


	.link {

		& + .link {
			padding-top: 15px;
		}

		a {
			color: $white;
			font-size: 0.875em;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-weight: 500;

	
			&:hover {
				text-decoration: underline;
			}
		}
	}



	.copyright {
		width: 100%;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;

		margin: 100px 0 0 0;
		border-top: 1px solid rgba(255, 255, 255, 0.25);
		padding: 20px 0 0 0;

		p {
			font-weight: normal;
			font-size: 0.75em;
			color: rgba(255, 255, 255, 0.7);
			padding: 0;

			&.credits {
				text-align: right;

				a {
					color: rgba(255, 255, 255, 0.7);
					border-bottom: 1px dotted;
				}
			}
		}
	}


}