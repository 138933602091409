header {
	padding: 20px 0;
	border-top: 5px solid $red;

	@include tablet-portrait {
		border-top: none;
		padding: 0;
		margin-bottom: -110px;
	}

	@include desktop {
		margin-bottom: -150px;
	}

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		z-index: 100;
		img {
			max-width: 140px;

			@include tablet-portrait {
				max-width: 160px;
				$offset: -50px;
				transform: translateY($offset);
				margin-bottom: $offset;
			}

			@include desktop {
				max-width: 200px;
				$offset: -50px;
				transform: translateY($offset);
				margin-bottom: $offset;
			}
		}
	}



	#toggle {
		width: 34px;
	    cursor: pointer;
	    padding: 8px 5px 8px 5px;
	    transition: all 0.6s cubic-bezier(0.7, 0.01, 0.3, 1);
	    margin-right: 10px;

	    @include tablet-portrait {
	    	display: none;
	    }

		.patty {
		    width: 40px;
		    height: 5px;
		    background: $blue;
		    transition: all 0.3s;

			&:after, &:before {
			    content: '';
			    display: block;
			    width: 40px;
			    height: 5px;
			    background: $blue;
			    position: absolute;
			    z-index: -1;
			    transition: all 0.3s;
			}
			&:before {
			    top: -10px;
			}
			&:after {
			    top: 10px;
			}
		}
	}

	&.open {

		#toggle .patty {
			background: none;

			&:after {
			    top: 0;
			    transform: rotate(45deg);
			}
			&:before {
			    top: 0;
			    transform: rotate(-45deg);
			}
		}

	}

}


nav#bar {
	background: $red;
	color: $white;
	padding: 20px 0;
	display: none;

	@include tablet-portrait {
		display: block !important;
	}


	.menu {
		width: 100%;
		padding: 10px 0 0 0;

		@include tablet-portrait {
			display: flex !important;
			width: auto;
			padding: 0 0 0 180px;
		}

		@include desktop {
			padding-left: 240px;
		}

		.link {
			display: flex;
			& + .link {
				@include tablet-portrait {
					margin: 0 0 0 25px;

					&:before {
						content: '·';
						padding-right: 25px;

					}
				}
			}
		}


		a {
			color: $white;
			display: block;
			font-size: 0.875em;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			font-weight: 500;
			padding: 15px 0;

			@include tablet-portrait {
				padding: 0;
			}





			&:hover {
				text-decoration: underline;
			}
		}
	}

	.mobile-links {
		border-top: 1px solid rgba(255, 255, 255, 0.25);
		padding-top: 10px;
		margin-top: 20px;
		a {
			text-transform: none;
			font-weight: 400;
			letter-spacing: 0.05em;

		}
		@include tablet-portrait {
			display: none;
		}
	}

}



#utility-nav {
	background: $blue;
	padding: 20px 0 18px 0;
	display: none;

	@include tablet-portrait {
		display: block;
	}

	.wrapper {
		display: flex;
		justify-content: flex-end;
	}

	a {
		font-size: 0.8125em;
		letter-spacing: 0.02em;
		font-weight: 500;
		display: block;
		color: $white;

		& + a {
			margin-left: 20px;
			padding-left: 20px;
			border-left: 1px solid rgba(255, 255, 255, 0.25);
		}

		&:hover {
			text-decoration: underline;
		}

	}

}