#nominees {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 960px;
	margin: 0 auto;

	.header {
		width: 100%;
		padding: 25px 0 50px 0;
		text-align: center;

		h2 {
			color: $red;
			text-transform: uppercase;
			letter-spacing: 0.05em;
		}
	}

	.nominee {
		width: 48%;
		margin: 0 0 50px 0;

		@include mobile-landscape {
			width: 31.3333%;
		}

		@include tablet-landscape {
			margin: 0 0 100px 0;
		}

		.headshot {
			

			img {
				margin: 0 auto;
			}
		}

		.info {
			text-align: center;
			padding: 10px 0 0 0;

			h3 {
				font-size: 1.25em;
				width: 100%;
				text-align: center;

				
				em {
					display: block;
					font-family: $merriweather;
					font-weight: 500;
					font-style: italic;
					font-size: 0.7em;
					padding: 10px 0 0 0;
				}

				a {
					color: $red;

					em {
						color: $slate;
					}
				}

			}
		}

	}

}