body.template-page {

	article {
		padding: 50px 0 50px 0;

		@include tablet-portrait {
			padding: 150px 0 75px 0;
		}

		.headline {
			padding: 0 0 50px 0;

			h1 {
				color: $blue;
				font-size: 1.75em;
				text-transform: uppercase;
				letter-spacing: 0.075em;
				font-weight: 600;

				@include tablet-portrait {
					font-size: 2.5em;
				}
			}
		}

		.copy {
			font-family: $merriweather;
			max-width: 800px;

			h4 {
				font-size: 1.125em;
				font-weight: 600;
				color: $red;
				padding-bottom: 10px;

				@include tablet-portrait {
					font-size: 1.25em;
				}
			}

			p {
				font-size: 1em;
				line-height: 1.5em;

				@include tablet-portrait {
					font-size: 1.125em;
				}

				& + p {
					padding-top: 40px;
				}

				& + h4 {
					padding-top: 40px;
				}
			}
		}
	}

}