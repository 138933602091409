body.home {

.hero-image {

	h1 {
		span {
			display: block;
		}
	}
}

	section#main {
		.wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
		}

		section#about {
			width: 100%;

			@include tablet-portrait {
				width: 65%;
			}

			@include desktop {
				width: 78.5%;
			}

			.headline {
				padding-bottom: 25px;

				h3 {
					color: $blue;
					text-transform: uppercase;
					letter-spacing: 0.075em;
					font-size: 1.375em;
				}
			}

			.copy {
				font-family: $merriweather;
				max-width: 640px;

				&.p2 {
					p {
						font-size: 1.125em;
						line-height: 1.5em;

						& + p {
							padding-top: 40px;
						}
					}
				}
			}


		}

		aside#latest-news {
			width: 100%;
			padding: 50px 0;

			@include tablet-portrait {
				width: 30%;
				padding: 0;
			}

			@include desktop {
				width: 18.5%;
			}

		}



	}



}