body.template-single-post {

	#news {

		@include tablet-portrait {
			padding: 100px 0;
		}


		article {
			font-family: $merriweather;
			width: 100%;
			max-width: 720px;
			padding: 0;

			@include tablet-landscape {
				margin: 0 0 0 10%;
			}
			
		}

	}


}