body.page-template-history {

	.winners {
		width: 100%;
		padding-top: 50px;

		& > h3 {
			padding-bottom: 20px;
		}

		.table-header,
		.table-body .entry {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.year {
				flex: 1;
			}

			.division {
				flex: 2;
				padding-right: 10px;
			}

		}

		.table-header {
			background: $red;
			padding: 10px 20px;
			h5 {
				text-transform: uppercase;
				letter-spacing: 0.0.75em;
				color: $white;
			}
		}

		.table-body {
			.entry {
				padding: 20px;

				&:nth-child(even) {
					background: $light-gray;
				}

				.year {
					h4 {
						font-size: 1em;
						color: $slate;
					}					
				}

				.division {
					h3 {
						font-size: 1em;
						color: $blue;
						padding: 0;
						font-weight: 600;

					}

					p {
						padding: 5px 0 0 0;
						margin: 0 !important;
						font-size: 0.8125em !important;
						font-style: italic;

					}
				}
			}
		}
	}


}