/* Media Queries
----------------------------------*/

$mobile-portrait-width: 480px;
$mobile-landscape-width: 640px;
$tablet-portrait-width: 768px;
$tablet-landscape-width: 1024px;
$desktop-width: 1280px;

@mixin mobile-portrait {
	@media screen and (min-width: #{$mobile-portrait-width}) {
		@content;
	}
}

@mixin mobile-landscape {
	@media screen and (min-width: #{$mobile-landscape-width}) {
		@content;
	}
}

@mixin tablet-portrait {
	@media screen and (min-width: #{$tablet-portrait-width}) {
		@content;
	}
}

@mixin tablet-landscape {
	@media screen and (min-width: #{$tablet-landscape-width}) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: #{$desktop-width}) {
		@content;
	}
}